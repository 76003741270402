<template>
    <div class="app-video">
        <!-- cross -->
        <div @click="close" class="video-cross">
            <img draggable="false" :src="crossIcon" alt="cross">    
        </div>
        <!-- book video -->
        <div class="book-video">
            <img draggable="false" :src="bookVideo" alt="book_video">
        </div>
        <!-- mask -->
        <app-mask></app-mask>
    </div>
</template>

<script>
import appMask from '@/components/AppMask'
export default {
    data() {
        return {
            crossIcon: require('@/assets/icons/cross.png'),
            bookVideo: require('@/assets/book_video.png')
        }
    },
    components: {
        appMask 
    }
}
</script>

<style lang="stylus" scoped>
.app-video
    img
        z-index 1000
        position fixed
    .video-cross
        img
            cursor pointer
            width 30px
            top 50px
            right 60px


    .book-video
        img 
            width 645px
            top 50%
            left 50%
            transform translate(-50%, -50%)

</style>



