<template>
  <div class="news-item">
    <router-link :to="'/news/detail/' + news.id">
      <img v-if="news.cover" :src="news.cover" alt="news-img" />
      <img v-else :src="defaultNewsCover" alt="news-img" />
    </router-link>
    <div class="news-title" v-text="news.title"></div>
    <div class="news-date">{{ news.created_at }}</div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    news: {},
  },
  data() {
    return {
      defaultNewsCover: require('@/assets/news.png')
    }
  },
  computed: {
    newsCreatedAt() {
      let createAt =  this.news.created_at
      if (!createAt) return "";
      createAt = createAt.toString();
      moment.locale("en");
      return moment(createAt).format("L");
    },
  },
  filters: {
    timeFormat: function (value) {
      if (!value) return "";
      value = value.toString();
      moment.locale("en");
      return moment().format("L");
      // return moment().startOf('day').fromNow();
    },
  },
};
</script>

<style lang="stylus" scoped>
.news-item {
  margin-top: 50px;

  a {
    display: inline-block;
    width: 325px;
    height: 220px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      transition: all 0.8s;

      &:hover {
        transform: scale(1.08);
      }
    }
  }

  .news-title {
    text-align: left;
    margin-top: 15px;
    font-size: 20px;
    line-height: 32px;
  }

  .news-date {
    text-align: left;
    font-size: 15px;
    line-height: 26px;
  }
}
</style>

