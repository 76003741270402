<template>
  <div class="app-index">
    <!-- header -->
    <app-header></app-header>
    <div class="index-wrapper">
      <!-- navigator -->
      <!-- <app-navigator :firstNav="'Home'" :firstUrl="'/'" :secondNav="'Jobs'" :secondUrl="'/jobs'"></app-navigator> -->

      <!-- book divider -->
      <book-divider :content="'Jobs'"></book-divider>

      <!-- jobs -->
      <div class="about-jobs">
        <div class="jobs-title">WE WANT YOU</div>

        <div class="jobs-subtitle">
          <div>SHANGHAI, CHINA</div>
          <div>Intern/Assistant Editor @ TJUP International Office</div>
        </div>
        <div class="jobs-content">
          <div class="jobs-content-title">
            Topic Planning /Editing / Proofreading / Graphic Design
          </div>
          <div>Creative and open-minded</div>
          <div>Proficiency in English (native speaker are preferred)</div>
          <div>Basic English editing skills</div>
          <div>Basic knowledge of AI and InDesign</div>
        </div>
      </div>

      <!-- divider -->
      <div class="index-divider"></div>

      <!-- jobs -->
      <div class="about-jobs">
        <div class="jobs-subtitle">WHO WE ARE</div>
        <div class="jobs-content">
          Tongji University Press (TJUP) was established in 1984. TJUP is the
          official publishing house of Tongji University, one of China's leading
          universities and research institutions. By establishing Urbanism,
          Architecture and civil engineering as its brand. Polytechnic and
          Medicine, Fundamental Disciplines, Art & Humanities and German and
          European Culture publishing as its major publishing areas.
        </div>

        <div class="jobs-subtitle">WHO ARE WE LOOKING FOR</div>
        <div class="jobs-content">
          Under the new situation, TJUP is entering an exciting new phase
          focused on building the international multiplatform. And so TJUP
          International Office was born. On the base of the international
          programme in Tongji University, the brand new platform is open to
          anyone who has passion for publishing, communication and curating.
        </div>

        <div class="jobs-subtitle">HOW TO APPLY</div>
        <div class="jobs-content">
          Please send your CV/Portfolio to crisieyuan@163.com Or contact Crisie
          Yuan on +8621-65980076
        </div>
      </div>

      <!-- divider -->
      <div class="index-divider"></div>
    </div>
    <!-- footer -->
    <app-footer></app-footer>
  </div>
</template>

<script>
import appHeader from "@/components/AppHeader";
import appFooter from "@/components/AppFooter";
import bookDivider from "@/components/book/BookDivider";

export default {
  name: "index",
  metaInfo: {
    title: "Links",
    titleTemplate: "%s - Tongji University Press",
    meta: [
      { name: "keywords", content: "Tongji University Press" },
      { name: "description", content: "Tongji University Press" },
    ],
  },
  data() {
    return {};
  },
  components: {
    appHeader,
    appFooter,
    bookDivider,
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>
$hover-color = #979797;
$border-color = #000;

.app-index {
  min-width: 1280px;

  .index-wrapper {
    width: 1035px;
    margin: 40px auto 0;

    .about-jobs {
      margin: 50px 0;
      text-align: left;

      .jobs-title {
        font-family: SourceSansPro-Bold;
        font-size: 36px;
      }

      .jobs-subtitle {
        font-family: SourceSansPro-SemiBold;
        font-weight: bold;
        display: inline-block;
        border-bottom: 2px #939EA9 solid;
        margin: 30px 0;
        font-size: 30px;
      }

      .jobs-content {
        font-family: SourceSansPro-Regular;
        font-size: 24px;

        .jobs-content-title {
          font-family: SourceSansPro-SemiBold;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }

    .index-divider {
      margin: 50px auto;
      border: 1px $border-color solid;
    }
  }
}
</style>
