<template>
    <div class="app-magnifer">
        <!-- cross -->
        <div @click="close()" class="magnifer-cross">
            <img draggable="false" src="../../static/img/icons/cross.png" alt="cross">    
        </div>
        <!-- book magnifer -->
        <div @click="prev()" class="left-arrow"><img src="../../static/img/left_arrow.png" /></div>
        <div class="book-magnifer">
            <img draggable="false" :src=imgArr[num] alt="book_magnifer">
        </div>
        <div @click="next()" class="right-arrow"><img src="../../static/img/right_arrow.png" /></div>
        <!-- mask -->
        <app-mask></app-mask>
    </div>
</template>

<script>
import appMask from '@/components/AppMask'
import bookApi from '../../api/book'
export default {
    components: {
        appMask
    },
    data () {
        return {
             book:{
                'title': ' Nourished Journal Three',
                'author': 'Edited by Boris Groys',
                'ISBN': 'English 978-84-96540-79-8',
                'summary': 'ffff',
                'cover': require('@/assets/book.png'),
                'paper_price':'',
                'purchase_url': '',
                'publish_date': '',
                'size': '',
                'catalogs': [],
                'adv_imgs': [],
                'description':'',
                'pages': '',
                'binding': ''
             },  
             imgArr: [
                '../static/img/banner1.png',
                '../static/img/banner2.png',
                '../static/img/banner3.png',
                '../static/img/banner4.png',
                '../static/img/banner5.png'
            ],
            num: 0, 
        }
    },
    mounted: function(){
        this.getBookDetail();
    },
    methods: { 
        getBookDetail:function(){
            let bookId =  this.$route.query.bookId || "0";
            if(bookId !== '0'){
                let promise = bookApi.getBookDetail(bookId);
                let self = this;
                promise.then(function(response){ 
                    let book = response.data.data;
                    self.book = book; 
                    self.imgArr = book.catalogs;
                }, function(error){
                  console.log(error); 
                  alert('获取图书详情失败' + error.response.data.error)
                });
            } 
        },
        next () {
            this.num ++
            if (this.num == this.imgArr.length) {
                this.num = 0
            }
            this.imgTab()
        },
        prev () {
            this.num --
            if (this.num == -1) {
                this.num = this.imgArr.length - 1
            }
            this.imgTab()
        },
        close (){
            this.$router.push('/book/detail?bookId=' + this.$route.query.bookId);
        }
    }
}
</script>

<style lang="stylus" scoped>
.app-magnifer
    img
        z-index 1000
        position fixed
    .magnifer-cross
        img
            cursor pointer
            width 30px
            top 50px
            right 60px

    .left-arrow
        img
            cursor pointer
            position absolute 
            top 300px
            left 120px 
    .right-arrow
        img
            cursor pointer
            top 300px
            right 120px 
    .book-magnifer
        img 
            width 814px
            top 50%
            left 50%
            transform translate(-50%, -50%)

</style>



