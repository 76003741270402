<template>
    <div class="app-index">
        <!-- header -->
        <app-header></app-header>
        <div class="index-wrapper">
            <!-- navigator -->
           <!--  <app-navigator :firstNav="'Home'" :firstUrl="'/'" :secondNav="'All'" :secondUrl="'/book/all'"></app-navigator>
         -->
            <!-- book divider -->
            <book-divider  :content="category.name"></book-divider>  
            <div>

                <!-- list title -->
                <!-- <list-title :title="item.categoryName" :titleUrl="'/'"></list-title> -->

                <!-- book list -->
                <ul class="book-list">
                    <li v-for="book in items.data" :key="book.id">
                        <book-item :book="book"></book-item>
                    </li>
                </ul>

                <div class="clearfix"></div>
            </div>
        </div>
        <!-- footer -->
        <app-footer></app-footer>
    </div>
</template>

<script>
 

import appHeader from '@/components/AppHeader';
import appFooter from '@/components/AppFooter';
 
import bookDivider from '@/components/book/BookDivider';
 
import bookItem from '@/components/book/BookItem';
import bookApi from '../../api/book'

export default {
    name: 'index',
    metaInfo: {
        title: 'BookListByCategory',
        titleTemplate: '%s - Tongji University Press',
        meta: [
            {name: 'keywords', content: 'Tongji University Press'},
            {name: 'description', content: 'Tongji University Press'}
        ]
    },
   
    data () {
        return {
            books: [ 
                {'id': 1, 'src': '', 'title': 'title1132323'},
                {'id': 2, 'src': '', 'title': 'title232323233'},
                {'id': 3, 'src': require('@/assets/logo/logo.png'), 'title': 'title44343434343434'},
                {'id': 4, 'src': '', 'title': 'title44343434343434'},
                {'id': 5, 'src': require('@/assets/logo/logo.png'), 'title': 'title44343434343434'},
                {'id': 6, 'src': require('@/assets/logo/logo.png'), 'title': 'title44343434343434'}
            ],
            items: [],
            category: {}  
        }
    },
    mounted:function(){
        this.getData();
    },
    components: {
        appHeader,
        appFooter,
 
        bookDivider,
      
        bookItem
    },
    computed: {
        currentCatId() {
            let catId =  this.$route.query.catId || '0';
            return parseInt(catId);
        },
        currentPage() {
            let p =  this.$route.query.page || 1;
            return parseInt(p); 
        },
        limit(){
            let l =  this.$route.query.limit || 24;
            return parseInt(l);
        },
        nextPage(){
           return this.currentPage + 1;
        },
        prevPage(){
            let p = this.currentPage - 1;
            if(p <= 0 ){
                p = 1;
            }
            return p;
        }
    },
    watch: {
       '$route': 'getData'
    }, 
    methods: { 
        getData:function(){
            let proms = bookApi.getBookCategory(this.currentCatId);
            let self1 = this;
            proms.then(function(response){  
                let result = response.data;
                console.log(result);
                if(result.success === true){ 
                    self1.category = result.category;
                }else{
                    //alert("目录不存在");
                    console.log('目录不存在');
                } 
            }, function(error){
                console.log(error); 
                alert('获取目录信息时报');
            });
            let promise = bookApi.bookList(this.currentCatId);
            let self = this;
            promise.then(function(response){ 
                let items = response.data;
                self.items = items 
            }, function(error){
              console.log(error); 
              alert('获取列表失败' + error.response.data.error)
            });
        }
    }
};
</script>

<style lang="stylus" scoped>
$border-color = #000

.app-index
    min-width 1280px

    .index-wrapper
        width 1035px
        margin 40px auto 0

        .book-list
            padding 0
            li
                float left
                list-style none
                margin 0 9.375px 60px 9.375px

        .clearfix
            clear both
            overflow hidden

</style>
