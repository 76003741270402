<template>
    <div class="book-menu">
        <div class="menu-wrapper">
            <div class="menu-left">
                <div class="menu-title">
                    Books
                </div>
                <ul>
                    <li>
                        <router-link to="/book/catalogs">Catalogs</router-link>
                    </li>
                    <li>
                        <router-link to="/book/all">All</router-link>
                    </li>
                </ul>
            </div>

            <div class="menu-right">
                <div class="book-recently">
                    Recently Posted
                </div>
                <div class="book-cover"> 
                    <router-link :to="'/book/detail?bookId=' + book.id"> 
                        <img :src="book.cover" alt="book-image">
                    </router-link>
                </div>
                <router-link :to="'/book/detail?bookId=' + book.id"> 
                    <div class="book-title" v-text="book.title"></div>
                </router-link>
            <!--   <div class="book-price" v-html="book.book_info"></div> -->
            </div>

            <div class="clearfix"></div>
        </div>
    </div>
</template>



<style lang="stylus" scoped>

$menu-bg = #A99593
$hover-color = #979797
$title-size = 45px

.book-menu
    z-index 1000
    width 100%
    height 500px
    position absolute
    top 131px

    .menu-wrapper
        width 1255px
        height 500px
        margin 0 auto

        .menu-left
            float left
            width 368px
            height 100%
            background #FFF

            .menu-title
                font-family SourceSansPro-Bold
                color #000
                margin 40px 0 0 40px
                font-size $title-size
                font-weight bold
                line-height 68px
                text-align left 

            ul
                margin-left 40px
                padding 0
                li  
                    line-height 40px
                    list-style none
                    text-align left
                    a
                        text-decoration none
                        font-family SourceSansPro-SemiBold
                        font-size 20px
                        color #000
                        &:hover
                            color $hover-color

        .menu-right
            float left
            width 368px
            height 100%
            background $menu-bg  

            .book-recently
                font-family SourceSansPro-Regular
                font-size 18px
                color white
                margin 70px 0

            .book-cover
                img 
                    width 170px

            .book-title
                font-family SourceSansPro-Regular
                font-size 18px
                color white
                margin 30px 0    

        .clearfix
            clear both
            overflow hidden        
</style>

<script>
export default {
    props: {
        book: {'cover': '', 'title': '', 'book_info': ''}, 
    },
    data () {
        return { 
        }
    }
}
</script>
