<template>
    <div class="search-bar">
        <div class="search-input">
            <input v-model="keywords" type="text" placeholder="type here" @keyup.enter="search()">
            <a @click="search()" href="javascript:;" class="searh-btn">SEARCH</a>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script> 

export default {
    name: 'search',
    metaInfo: {
        title: 'Search',
        titleTemplate: '%s - Tongji University Press'
    },
    data () {
        return { 
            keywords: ''
        }
    }, 
    
    methods: { 
        search:function(){ 
            let url = `/search?keywords=${this.keywords}`;
            console.log(url);
            this.$router.push(url);
        }
    }
};
</script>

<style lang="stylus" scoped>

$hover-color = #979797
$font-size = 24px
$search-size = 15px


.search-bar
    width 100%
    height 330px
    background #fff
    position absolute
    top 131px
    z-index 1000

    .search-input
        width 602px
        margin 80px auto 0
        input
            float left
            display inline
            font-size $font-size
            height 40px
            line-height 42px
            width 500px
            outline none
            border none
            border-bottom 1px #000 solid

        .searh-btn
            float left
            display inline
            vertical-align bottom
            text-decoration none
            width 100px
            height 42px
            line-height 42px
            border-bottom 1px #000 solid
            font-size $search-size
            color #000
            &:hover
                color $hover-color

        .clearfix
            clear both
            overflow hidden        
</style>
