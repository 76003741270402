<template>
    <div class="app-mask" @click="gotoIndex">

    </div>
</template>
<script>
    export default {
        methods: { 
            gotoIndex:function(){ 
                this.$router.push('/');
            }
        }
    }
</script>

<style lang="stylus" scoped>
.app-mask
    width 100%
    height 100%
    position fixed
    z-index 999
    background-color rgba(0, 0, 0, .7)
</style>

