<template>
    <div class="app-wechat">
        <!-- cross -->
        <div @click="close" class="wechat-cross">
            <img draggable="false" src="../static/img/icons/cross.png" alt="cross">    
        </div>
        <!-- wechat qrcode -->
        <div class="wechat-qrcode">
            <img draggable="false" src="../static/img/icons/qrcode.png" alt="qrcode">
        </div>
        <!-- mask -->
        <app-mask></app-mask>
    </div>
</template>

<script>
import appMask from '@/components/AppMask'
export default {
    components: {
        appMask
    },
    methods: {
        close: function(){
            this.$router.push('/');
        }
    }
}
</script>

<style lang="stylus" scoped>
.app-wechat
    img
        z-index 1000
        position fixed
    .wechat-cross
        img
            cursor pointer
            width 30px
            top 50px
            right 60px


    .wechat-qrcode
        img 
            width 200px
            top 50%
            left 50%
            transform translate(-50%, -50%)

</style>



