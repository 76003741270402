<template>
    <div class="app-index">
        <!-- header -->
        <app-header></app-header>
        <div class="index-wrapper">

            <!-- navigator -->
           <!--  <app-navigator :firstNav="'Home'" :firstUrl="'/'" :secondNav="'Links'" :secondUrl="'/links'"></app-navigator> -->

            <!-- book divider -->
            <book-divider :content="'Links'"></book-divider>

            <!-- links -->
            <div class="about-links">
                <div class="links-title" id="englishJournals">English Journals</div>

                <div class="links-subtitle">Built Heritage</div>
                <a href="https://www.built-heritage.net/">
                    https://www.built-heritage.net/
                </a>
                
                <div class="links-subtitle">She Ji: The Journal of Design, Economics, and Innovation</div>
                <a href="https://www.journals.elsevier.com/she-ji-the-journal-of-design-economics-and-innovation/">
                    https://www.journals.elsevier.com/she-ji-the-journal-of-design-economics-and-innovation/
                </a>

                <div class="links-subtitle">Landscape and Urban Planning</div>
                <a href="https://www.journals.elsevier.com/landscape-and-urban-planning/">
                    https://www.journals.elsevier.com/landscape-and-urban-planning/
                </a>
                
                <div class="links-subtitle">Underground Space</div>
                <a href="https://www.journals.elsevier.com/underground-space">
                    https://www.journals.elsevier.com/underground-space
                </a>
            </div>

            <!-- divider -->
            <div class="index-divider"></div>

            <!-- links -->
            <div class="about-links">
                <div class="links-title" id="tongjiUniversity">Tongji University</div>

                <div class="links-subtitle">Tongji University</div>
                <a href="http://en.tongji.edu.cn/">
                    http://en.tongji.edu.cn/
                </a>
                
                <div class="links-subtitle">College of Architecture and Urban Planning (CAUP)</div>
                <a href="http://en-caup.tongji.edu.cn/">
                    http://en-caup.tongji.edu.cn/
                </a>

                <div class="links-subtitle">College of Design and Innovation (D&I)</div>
                <a href="http://tjdi.tongji.edu.cn/?lang=_en">
                    http://tjdi.tongji.edu.cn/?lang=_en
                </a>
                
                <div class="links-subtitle">College of Civil Engineering</div>
                <a href="http://civileng.tongji.edu.cn/en/">
                    http://civileng.tongji.edu.cn/en/
                </a>
            </div>

            <!-- divider -->
            <div class="index-divider"></div>
           
        </div>
        <!-- footer -->
        <app-footer></app-footer>
    </div>
</template>

<script>
import appHeader from '@/components/AppHeader';
import appFooter from '@/components/AppFooter';
import bookDivider from '@/components/book/BookDivider';
 
export default {
    name: 'index',
    metaInfo: {
        title: 'Links',
        titleTemplate: '%s - Tongji University Press',
        meta: [
            {name: 'keywords', content: 'Tongji University Press'},
            {name: 'description', content: 'Tongji University Press'}
        ]
    },
    data () {
        return {
        }
    },
    components: {
        appHeader,
        appFooter,
        bookDivider, 
    },
    methods: {
    }
};
</script>

<style lang="stylus" scoped>
$hover-color = #979797
$border-color = #000

.app-index
    min-width 1280px

    .index-wrapper
        width 1035px
        margin 40px auto 0

        .about-links
            margin 50px 0
            text-align left 
    
            .links-title
                font-family SourceSansPro-SemiBold
                font-weight bold
                font-size 36px
                color #939EA9
            .links-subtitle
                font-family SourceSansPro-SemiBold
                font-weight bold
                margin-top 30px
                font-size 24px
                color #231916
            a
                font-family SourceSansPro-Regular
                font-size 24px
                color #000
                &:hover
                    color $hover-color        
                    

        .index-divider
            margin 50px auto
            border 1px $border-color solid


</style>
