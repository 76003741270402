<template>
    <div class="app-index">
        <!-- header -->
        <router-linkpp-header></router-linkpp-header>
        <div class="index-wrapper">

            <!-- navigator -->
           <!--  <router-linkpp-navigator 
            :firstNav="'Home'" :firstUrl="'/'" 
            :secondNav="'English titles'" :secondUrl="'/book/all'"
            :thirdNav="'Nourished Journal Three'" :thirdUrl="'/book/detail'"
            ></router-linkpp-navigator> -->

            <!-- book detail -->
            <div class="book-detail">
                <div class="detail-left">
                    <div class="detail-img">
                        <img :src="book.cover" alt="detail_book_img">
                    </div>
                    <ul>
                        <li>
                            <router-link :to="'/book/magnifer?bookId=' + book.id">
                                <img src="../../static/img/icons/shopping_magnifer.png" alt="amazon">
                            </router-link>
                        </li>
                        <li>
                            <router-link target="_blank" :to="book.purchase_url">
                                <img src="../../static/img/icons/shopping_cart.png" alt="amazon">
                            </router-link>
                        </li>
                       <!--  <li>    
                            <router-link to="/book/video">
                                <img src="../../static/img/icons/shopping_video.png" alt="amazon">
                            </router-link>
                        </li> -->
                    </ul>
                    <div class="clearfix"></div>
                    <div class="link-line"></div>
                </div>
                <div class="detail-right">
                    <div class="detail-title">
                        {{book.title}}
                    </div>
                    <div class="detail-author">
                        {{book.author}}
                    </div>
                    <div class="detail-specification">
                        <div v-html="book.book_info"></div> 
                    </div>
                    <div class="detail-overview">
                        <div class="overview-title">Overview</div>
                    </div>
                    <div class="overview-content" v-html="book.description"> 
                    </div>
                </div>

                <div class="clearfix"></div>
            </div>

            <div v-show="allAdvImgs.length > 0" class="related-books-header">
               <div class="related-books-header-title">Sample Pages</div>
            </div>  

            <!-- related books -->
            <div v-show="allAdvImgs.length > 0" class="related-books">
                <ul class="adv-imgs-list">
                    <li v-for="(img,index) in advImgs" :key="index">
                        <router-link :to="img"><img  :src="img" /></router-link>
                    </li>
                </ul> 
                <!-- bullet 小圆点 -->
                <div class="bullet">
                    <!-- prev btn -->
                    <router-link to="javascript:;" @click="prev()" class="prev">
                        <img src="../../static/img/icons/left-arrow-grey.png" alt="left-arrow">
                    </router-link> 
                    <!-- next btn -->
                    <router-link to="javascript:;" @click="next()" class="next">
                        <img src="../../static/img/icons/right-arrow-grey.png" alt="right-arrow">
                    </router-link>
                </div> 

            </div> 
            <div class="detail-divider"></div>
        </div>
        <!-- footer -->
        <router-linkpp-footer></router-linkpp-footer>
    </div>
</template>

<script>

import bookApi from '../../api/book'

export default {
    name: 'index',
    metaInfo: {
        title: 'Detail',
        titleTemplate: '%s - Tongji University Press',
        meta: [
            {name: 'keywords', content: 'Tongji University Press'},
            {name: 'description', content: 'Tongji University Press'}
        ]
    },
    data () {
        return {
             book:{
                'title': ' Nourished Journal Three',
                'author': 'Edited by Boris Groys',
                'ISBN': 'English 978-84-96540-79-8',
                'summary': 'ffff',
                'cover': require('@/assets/book.png'),
                'paper_price':'',
                'purchase_url': '',
                'publish_date': '',
                'size': '',
                'catalog': '',
                'description':'',
                'pages': '',
                'binding': ''
             },
             allAdvImgs:[],
             pages: 1,
             currentPage: 1, 
             advImgs: [ 
                '../static/img/catalogs2018.png',
                '../static/img/catalogs2018.png',
                '../static/img/catalogs2018.png' 
            ]
        }
    },
    components: {
        
    },
    mounted: function(){
        this.getBookDetail();
    },
    methods: { 
        getBookDetail:function(){
            let bookId =  this.$route.query.bookId || "0";
            if(bookId !== '0'){
                let promise = bookApi.getBookDetail(bookId);
                let self = this;
                promise.then(function(response){ 
                    let book = response.data.data;
                    self.book = book; 
                    if(book.adv_imgs.length > 0){
                        self.allAdvImgs = book.adv_imgs;
                        self.advImgs = book.adv_imgs.slice(0,3);
                        self.pages = Math.ceil(book.adv_imgs.length / 3); 
                    }else{
                        self.advImgs = [];
                    }
                }, function(error){
                  console.log(error); 
                  alert('获取图书详情失败' + error.response.data.error)
                });
            } 
        },
        next () {  
            if( this.pages > 1){
                let start = this.currentPage * 3;
                let end = start + 3;
                this.advImgs = this.allAdvImgs.slice(start, end);
                
                console.log(this.advImgs);
                this.currentPage += 1;
            }
        },
        prev () {
            if( this.currentPage > 1){
                let start = (this.currentPage - 1) * 3 - 3;
                let end = start + 3;
                if(start >= 0){
                   this.advImgs = this.allAdvImgs.slice(start, end);
                    this.currentPage -= 1; 
                } 
            }
        },
    }
};
</script>

<style lang="stylus" scoped>
$border-color = #000

.app-index
    min-width 1280px

    .index-wrapper
        width 1035px
        margin 40px auto 0
        
        .book-detail
            margin 50px 0
            .detail-left
                float left
                width 350px
                .detail-img
                    img
                        display block
                        width 350px
                ul
                    margin 30px 0
                    padding 0
                    overflow hidden
                    li
                        float left
                        list-style none
                        margin-right 20px 
                        a
                            img
                                width 30px 
                .clearfix
                    clear both
                    overflow hidden
                .link-line
                    border-bottom 2px #000 solid                     

            .detail-right
                float left
                width 635px
                text-align left
                margin-left 50px
                .detail-title
                    font-family SourceSansPro-SemiBold
                    font-size 45px
                    font-weight bold

                .detail-author
                    font-family SourceSansPro-SemiBold
                    font-size 24px
                    font-weight bold 

                .detail-specification
                    font-family SourceSansPro-SemiBold
                    margin-top 180px
                    font-size 22px   
                    
                    p 
                        font-size 18px 


                .detail-overview
                    margin 80px 0 20px 0
                    font-size 24px
                    font-weight bold
                    line-height 37px
                    border-bottom 2px #000 solid

                    .overview-title
                        font-family SourceSansPro-Bold
                        font-weight bold
                        line-height 40px
                        display inline-block
                        border-bottom 2px #000 solid

                .overview-content
                    font-family SourceSansPro-Regular
                    font-size 18px


            .clearfix
                clear both
                overflow hidden 

        .related-books-header
            text-align left 
            padding-bottom  12px
            border-bottom: 2px solid #000;

            .related-books-header-title
                font-family SourceSansPro-Bold
                font-size 24px
                font-weight bold

        .detail-divider
            margin 50px auto
            border 1px $border-color solid

        .related-books
            text-align left
            width 1035px 

            ul
                display block
                padding 0
                margin 30px auto 
                white-space nowrap

                li 
                    margin 0 35px 10px 0px
                    display inline-block
                    
                    list-style none  

                    a
                        display inline-block
                        width 310px
                        height 210px 
                        overflow hidden

                        img
                            width 100%
                            height 100%
                            transition all 0.8s
                            &:hover
                                transform scale(1.08)

            .bullet 
                position relative
                bottom 0
                margin 0 auto
                text-align center
                z-index 100

                .prev
                    position absolute
                    left 0px
                    img
                        width 27px

                .next
                    position absolute
                    right 0px
                    img
                        width 27px
</style>
