<template>
    <div class="catalog-item">
        <a target="_blank" :href="book.pdf" :download="book.pdf">
            <img v-if="book._id" :src="book.cover" alt="catalog-image">
            <img v-else :src="defaultBookCover" alt="catalog-image">
        </a>
        <div class="catalog-title" v-text="book.title"></div>
    </div>
</template>

<script>
 

export default {
    props: {
        book: {}, 
    },
    data() {
        return {
            defaultBookCover: require('@/assets/book.png')
        }
    }
}
</script>

<style lang="stylus" scoped>

$font-color = #000

.catalog-item
    a
        display inline-block
        width 315px
        height 401px
        overflow hidden
        img
            width 100% 
            transition all 0.8s
            &:hover
                transform scale(1.08)

    .catalog-title
        text-align left 
        margin-top 30px
        font-size 18px
        font-color $font-color    
</style>


