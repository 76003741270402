<template>
    <div class="app-index">
        <!-- header -->
        <app-header></app-header>
        <div class="index-wrapper">

            <!-- navigator -->
           <!--  <app-navigator :firstNav="'Home'" :firstUrl="'/'" :secondNav="'News'" :secondUrl="'/news/all'"></app-navigator> -->

            <!-- book divider -->
            <book-divider :content="'News'"></book-divider>

            <!-- news -->
            <div class="app-news">

                <!-- news list -->
                <ul>
                    <li v-for="news in newsList" :key="news._id">
                        <news-item :news="news"></news-item>
                    </li>
                </ul>

                <!-- clearfix -->
                <div class="clearfix"></div>

                <!-- paginator -->
                <div class="app-paginator">
                    <!-- prev -->
                    <a href="javascript:;">
                        <div @click="goToPage(prevPage)" class="prev-page"></div>
                    </a>
                    <!-- pages -->
                    <a class="activated" href="javascript:;" v-for="page in pages" :key="page">
                        <div @click="goToPage(page)" class="pages" v-text="page"></div>
                    </a>
                    <!-- next -->
                    <a href="javascript:;">
                        <div @click="goToPage(nextPage)" class="next-page"></div>
                    </a>
                </div>
            </div>
           
        </div>
        <!-- footer -->
        <app-footer></app-footer>
    </div>
</template>

<script>
import appHeader from '@/components/AppHeader';
import appFooter from '@/components/AppFooter';
import bookDivider from '@/components/book/BookDivider';
 
import newsItem from '@/components/news/NewsItem';
import newsApi from '../../api/news';
 

export default {
    name: 'index',
    metaInfo: {
        title: 'News',
        titleTemplate: '%s - Tongji University Press',
        meta: [
            {name: 'keywords', content: 'Tongji University Press'},
            {name: 'description', content: 'Tongji University Press'}
        ]
    },
    data () {
        return {
            newsList: [
                {id: '1', title: 'title1111', created_at: ' October,15th 2017'},
                {id: '2', title: 'title1111', created_at: ' October,15th 2017'},
                {id: '3', title: 'title1111', created_at: ' October,15th 2017'},
                {id: '4', title: 'title1111', created_at: ' October,15th 2017'}
            ],
            pages: 5
        }
    },
    mounted:function(){
        this.getData();
    },
    components: {
        appHeader,
        appFooter,
        bookDivider,
 
        newsItem
    },
    computed: {
        currentPage() {
            let p =  this.$route.query.page || 1;
            return parseInt(p); 
        },
        limit(){
            let l =  this.$route.query.limit || 24;
            return parseInt(l);
        },
        nextPage(){
           return this.currentPage + 1;
        },
        prevPage(){
            let p = this.currentPage - 1;
            if(p <= 0 ){
                p = 1;
            }
            return p;
        }
    },
    watch: {
       '$route': 'getData'
    }, 
   
    methods: { 
        getData:function(){
            let promise = newsApi.getLatestNews(this.currentPage, this.limit);
            let self = this;
            promise.then(function(response){ 
                let items = response.data.data;
                let total = response.data.total || 1;
                let pages = Math.ceil(total / self.limit);
                self.newsList = items
                self.pages = pages;

            }, function(error){
              console.log(error); 
              alert('获取列表失败' + error.response.data.error)
            });
        },
        goToPage: function(page){
            let url = `/news/all?page=${page}`;
            this.$router.push(url);
        }
    }
};
</script>

<style lang="stylus" scoped>
$hover-color = #979797
$border-color = #000

.app-index
    min-width 1280px

    .index-wrapper
        width 1035px
        margin 40px auto 0

        .app-news
            ul
                margin 0
                padding 0
                li
                    margin 0 10px
                    list-style none
                    float left

            .clearfix
                clear both
                overflow hidden

            .app-paginator
                margin 50px 0
                a      
                    display inline-block
                    margin 0 5px
                    width 54px
                    height 60px
                    line-height 60px
                    border-bottom 1px #CFCECE solid
                    text-decoration none
                    color #000
                    font-size 18px
                    &:hover
                        color #979797

                    .prev-page
                        display inline-block
                        width 0
                        height 0
                        border-top 7px solid transparent
                        border-bottom 7px solid transparent
                        border-right 7px #000 solid
                    .next-page
                        display inline-block
                        width 0
                        height 0
                        border-top 7px solid transparent
                        border-bottom 7px solid transparent
                        border-left 7px #000 solid

                .activated
                    border-bottom 1px #000 solid  
</style>
