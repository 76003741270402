<template>
  <div class="app-index">
    <!-- header -->
    <router-linkpp-header></router-linkpp-header>
    <div class="index-wrapper">
      <!-- navigator -->
      <!--  <router-linkpp-navigator 
            :firstNav="'Home'" :firstUrl="'/'" 
            :secondNav="'English titles'" :secondUrl="'/book/all'"
            :thirdNav="'Nourished Journal Three'" :thirdUrl="'/book/detail'"
            ></router-linkpp-navigator> -->

      <!-- book detail -->
      <div class="book-detail">
        <div class="detail-left">
          <div class="detail-img">
            <img :src="book.cover" alt="detail_book_img" />
          </div>
          <ul>
            <li>
              <router-link to="/book/magnifer">
                <img
                  :src="shoppingMagniferIcon"
                  alt="amazon"
                />
              </router-link>
            </li>
            <li>
              <router-link to="/amazon">
                <img
                  :src="shoppingCartIcon"
                  alt="amazon"
                />
              </router-link>
            </li>
            <li>
              <router-link to="/book/video">
                <img
                  :src="shoppingVideoIcon"
                  alt="amazon"
                />
              </router-link>
            </li>
          </ul>
          <div class="clearfix"></div>
          <div class="link-line"></div>
        </div>
        <div class="detail-right">
          <div class="detail-title">
            {{ book.title }}
          </div>
          <div class="detail-author">
            {{ book.author }}
          </div>
          <div class="detail-specification">
            <div v-html="book.book_info"></div>
          </div>
          <div class="detail-overview">
            <div class="overview-title">Overview</div>
          </div>
          <div class="overview-content" v-html="book.description"></div>
        </div>

        <div class="clearfix"></div>
      </div>

      <div class="detail-divider"></div>

      <!-- related books -->
      <div class="related-books"></div>

      <div class="detail-divider"></div>
    </div>
    <!-- footer -->
    <router-linkpp-footer></router-linkpp-footer>
  </div>
</template>

<script>
import bookApi from "../../../api/book";

export default {
  name: "index",
  metaInfo: {
    title: "BookDetail",
    titleTemplate: "%s - Tongji University Press",
    meta: [
      { name: "keywords", content: "Tongji University Press" },
      { name: "description", content: "Tongji University Press" },
    ],
  },

  data() {
    return {
      book: {
        title: " Nourished Journal Three",
        author: "Edited by Boris Groys",
        ISBN: "English 978-84-96540-79-8",
        summary: "ffff",
        cover: require('@/assets/book.png'),
        paper_price: "",
        purchase_url: "",
        publish_date: "",
        size: "",
        catalog: "",
        description: "",
        pages: "",
        binding: "",
        shoppingMagniferIcon: require('@/assets/icons/shopping_magnifer.png'),
        shoppingCartIcon: require('@/assets/icons/shopping_cart.png'),
        shoppingVideoIcon: require('@/assets/icons/shopping_video.png'),
      },
      cover_prefx: "http://localhost:8000",
    };
  },
  components: {},
  mounted: function () {
    this.getBookDetail();
  },
  methods: {
    getBookDetail: function () {
      let bookId = this.$route.query.bookId || "0";
      if (bookId !== "0") {
        let promise = bookApi.getBookDetail(bookId);
        let self = this;
        promise.then(
          function (response) {
            let book = response.data.data;
            self.book = book;
          },
          function (error) {
            console.log(error);
            alert("获取图书详情失败" + error.response.data.error);
          }
        );
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
$border-color = #000;

.app-index {
  min-width: 1280px;

  .index-wrapper {
    width: 1035px;
    margin: 40px auto 0;

    .book-detail {
      margin: 50px 0;

      .detail-left {
        float: left;
        width: 350px;

        .detail-img {
          img {
            display: block;
            width: 350px;
          }
        }

        ul {
          margin: 30px 0;
          padding: 0;
          overflow: hidden;

          li {
            float: left;
            list-style: none;
            margin-right: 20px;

            a {
              img {
                width: 30px;
              }
            }
          }
        }

        .clearfix {
          clear: both;
          overflow: hidden;
        }

        .link-line {
          border-bottom: 2px #000 solid;
        }
      }

      .detail-right {
        float: left;
        width: 635px;
        text-align: left;
        margin-left: 50px;

        .detail-title {
          font-size: 45px;
          font-weight: bold;
        }

        .detail-author {
          font-size: 24px;
          font-weight: bold;
        }

        .detail-specification {
          margin-top: 180px;
          font-size: 22px;

          p {
            font-size: 18px;
          }
        }

        .detail-overview {
          margin: 80px 0 20px 0;
          font-size: 24px;
          font-weight: bold;
          line-height: 37px;
          border-bottom: 2px #000 solid;

          .overview-title {
            line-height: 40px;
            display: inline-block;
            border-bottom: 2px #000 solid;
          }
        }

        .overview-content {
          font-size: 18px;
        }
      }

      .clearfix {
        clear: both;
        overflow: hidden;
      }
    }

    .detail-divider {
      margin: 50px auto;
      border: 1px $border-color solid;
    }

    .related-books {
      width: 100%;
    }
  }
}
</style>
