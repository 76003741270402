<template>
    <div class="list-title">
        <div class="list-wrapper">
            <router-link target="_blank" :to="titleUrl">
                <span v-text="title"></span>
                <!-- <span style="" v-show="more===true">&nbsp;&nbsp;....</span> -->
                <img v-show="more===true" src="../../static/img/icons/title_arrow.png" alt="arrow" v-if="title">
            </router-link>
            

            <!-- title arrow -->
            
            <!-- <img v-show="more===true" src="../../static/img/icons/title_arrow.png" alt="arrow" v-if="title"> -->
        </div>
    </div>
</template> 
<script>
export default {
    props: {
        titleUrl: String,
        title: String,
        more: Boolean
    }
}
</script>


<style lang="stylus" scoped>
$hover-color = #979797
$font-size = 20px

.list-title
    width 100%
    margin-top 20px
    overflow hidden

    .list-wrapper
        min-width 1035px
        text-align left
        font-size $font-size
        line-height 30px
        a
            text-decoration none
            color #000
            &:hover
                color $hover-color

        img
            display inline-block
            width 28px        
</style>
