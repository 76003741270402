<template>
    <div class="app-index">
        <!-- header -->
        <app-header></app-header>
        <div class="index-wrapper">

            <!-- navigator -->
           <!--  <app-navigator :firstNav="'Home'" :firstUrl="'/'" :secondNav="'Contact'" :secondUrl="'/contact'"></app-navigator> -->

            <!-- book divider -->
            <book-divider :content="'Contact'"></book-divider>

            <!-- contact -->
            <div class="about-contact">
                <div class="contact-title" id="generalContact">General Contact</div>

                <div class="contact-group">
                    <div class="contact-item first">
                        <div class="contact-subtitle">ADDRESS</div>
                        <div class="contact-content">
                            <div>No. 2 Chifeng Rd., </div>
                            <div>Yangpu District </div>
                            <div>Shanghai 200092, P.R. China</div>
                        </div>
                    </div>

                    <div class="contact-item second">
                        <div class="contact-subtitle">PHONE</div>
                        <div class="contact-content">+8621-659814742</div>
                    </div>

                    <div class="contact-item third">
                        <div class="contact-subtitle">EMAIL</div>
                        <div class="contact-content">cbs@tongji.edu.cn</div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            
            </div>
            

            <!-- divider -->
            <div class="index-divider"></div>

            <!-- contact -->
            <div class="about-contact">
                <div class="contact-title" id="tjupInternationalOffice">TJUP International Office</div>

                <div class="contact-group">
                    <div class="contact-item first">
                        <div class="contact-subtitle">ADDRESS</div>
                        <div class="contact-content">
                            <div>Rm303, Building 1, No. 2 Chifeng Rd.,</div> 
                            <div>Yangpu District</div> 
                            <div>Shanghai 200092, P.R. China</div>
                        </div>
                    </div>

                    <div class="contact-item second">
                        <div class="contact-subtitle">PHONE</div>
                        <div class="contact-content">+8621-65985580</div>
                    </div>

                    <div class="contact-item third">
                        <div class="contact-subtitle">EMAIL</div>
                        <div class="contact-content">tjup_intl@163.com</div>
                    </div>
                    <div class="clearfix"></div>
                </div>
            
            </div>

            <!-- divider -->
            <div class="index-divider"></div>

            <!-- contact map -->
            <div class="contact-map">
                <img src="../static/img/map.jpg" alt="map">
                <div class="map-info">
                    <div class="map-title">Map</div> 
                    <div class="map-address">No. 2 Chifeng Rd.,</div>
                    <div class="map-address">Yangpu District</div>
                    <div class="map-address">Shanghai 200092,</div>
                    <div class="map-address">P.R. China</div> 
                    <div class="map-link"><a href="http://www.google.cn/maps/place/Tongji+University+Press+%EF%BC%88Chifeng+Road%EF%BC%89,+Yangpu+Qu,+Shanghai+Shi,+200000/@31.2797872,121.5024476,18.77z/data=!4m5!3m4!1s0x35b271649856b0bf:0x39ce04ec22fb820b!8m2!3d31.2793828!4d121.5044804?hl=en">Get Directions</a></div>
                </div>
                 <!-- <p><iframe src="http://www.google.cn/maps/embed?pb=!1m18!1m12!1m3!1d3757.451003135688!2d121.50399348356524!3d31.27722913816373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35b271649856b0bf%3A0x39ce04ec22fb820b!2sTongji+University+Press+%EF%BC%88Chifeng+Road%EF%BC%89%2C+Yangpu+Qu%2C+Shanghai+Shi%2C+200000!5e0!3m2!1sen!2scn!4v1530522665171" width="1035" height="450" frameborder="0" style="border:0" allowfullscreen></iframe></p> -->
            </div>
        </div>
        <!-- footer -->
        <app-footer></app-footer>
    </div>
</template>

<script>
import appHeader from '@/components/AppHeader';
import appFooter from '@/components/AppFooter';
import bookDivider from '@/components/book/BookDivider';
 
export default {
    name: 'index',
    metaInfo: {
        title: 'Links',
        titleTemplate: '%s - Tongji University Press',
        meta: [
            {name: 'keywords', content: 'Tongji University Press'},
            {name: 'description', content: 'Tongji University Press'}
        ]
    },
    data () {
        return {
        }
    },
    components: {
        appHeader,
        appFooter,
        bookDivider,
       
    },
    methods: {
    }
};
</script>

<style lang="stylus" scoped>
$border-color = #000

.app-index
    min-width 1280px

    .index-wrapper
        width 1035px
        margin 40px auto 0

        .about-contact
            margin 50px 0
            text-align left 
    
            .contact-title
                font-family SourceSansPro-Bold
                font-weight bold
                font-size 40px
                color #939EA9

            .contact-group
                margin-top 40px
                
                .contact-item
                    float left
                    text-align left
                    width 33.33%
                    .contact-subtitle
                        font-family SourceSansPro-SemiBold
                        font-weight bold
                        display inline-block
                        border-bottom 2px #939EA9 solid
                        font-size 36px
                        color #231916

                    .contact-content
                        font-family SourceSansPro-Regular
                        text-align left
                        margin-top 30px
                        font-size 24px

                .first
                    width 40%
                .second
                    width 30%
                .third
                    width 30%

                .clearfix
                    clear both
                    overflow hidden        
                    
        .index-divider
            margin 50px auto
            border 1px $border-color solid

        .contact-map
            width 100%
            position relative

            .map-info
                background-color white
                width 227px
                height 247px
                padding 40px
                z-index 100
                position absolute
                left 68px
                bottom 40px
                text-align left
                font-font-family  SourceSansPro-Regular
                font-size 18px

                .map-title
                    font-family SourceSansPro-SemiBold
                    font-font-weight  bold
                    font-size 24px
                    margin-bottom  60px

                .map-address 
                    height 24px
                    line-height 24px

                .map-link 
                    margin-top 40px

  
            img
                display block
                width 1035px
                height 570px

</style>
