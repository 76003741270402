<template>
    <div class="about-menu">
        <div class="menu-wrapper">
             <div class="menu-left">
                <div class="menu-title">
                    About
                </div>
                <ul>
                    <li>
                        <router-link to="/about/tjup">TJUP</router-link>
                    </li>
                    <li>
                        <router-link to="/news/all">News</router-link>
                    </li>
                    <li>
                        <router-link to="/jobs">Jobs</router-link>
                    </li>
                </ul>
            </div>
            <div class="menu-right">
                <div class="menu-about">
                Established in 1984, led by Ministry of Education of the People’s Republic of China, Tongji University Press (TJUP) is the official publishing house of Tongji University, one of China’s leading universities and research institutions. 
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
       
    </div>
</template>

<style lang="stylus" scoped>

$menu-bg = #939EA9
$hover-color = #979797
$title-size = 45px

.about-menu
    z-index 1000
    width 100%
    height 500px
    position absolute
    top 131px
    
    .menu-wrapper
        width 1255px
        height 500px
        margin 0 auto

        .menu-left
            float left
            width 368px
            height 100%
            background #FFF

            .menu-title
                font-family SourceSansPro-Bold
                color #000
                margin 40px 0 0 40px
                font-size $title-size
                font-weight bold
                line-height 68px
                text-align left 

            ul
                margin-left 40px
                padding 0
                li  
                    line-height 40px
                    list-style none
                    text-align left
                    a
                        font-family SourceSansPro-SemiBold 
                        text-decoration none
                        font-size 20px
                        color #000
                        &:hover
                            color $hover-color

        .menu-right
            float left
            width 368px
            height 100%
            background $menu-bg
            .menu-about
                font-family SourceSansPro-SemiBold
                font-style italic
                font-size 20px
                color white
                width 270px
                position relative
                top 50%
                left 50%
                transform translate(-50%, -50%)
                text-align left

        .clearfix
            clear both
            overflow hidden    


</style>
