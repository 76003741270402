<template>
  <div class="app-index">
    <!-- header -->
    <app-header></app-header>
    <div class="index-wrapper">
      <!-- navigator -->
      <!-- <app-navigator :firstNav="'Home'" :firstUrl="'/'" :secondNav="'Cataglogs'" :secondUrl="'/book/catalogs'"></app-navigator> -->

      <!-- book divider -->
      <book-divider :content="'Catalogs'"></book-divider>

      <!-- cataloglist -->
      <ul class="catalog-list">
        <li v-for="book in books" :key="book._id">
          <catalog-item :book="book"></catalog-item>
        </li>
      </ul>

      <div class="clearfix"></div>
    </div>
    <!-- footer -->
    <app-footer></app-footer>
  </div>
</template>

<script>
import appHeader from "@/components/AppHeader";
import appFooter from "@/components/AppFooter";

import bookDivider from "@/components/book/BookDivider";
import catalogItem from "@/components/catalog/CatalogItem";
export default {
  name: "index",
  metaInfo: {
    title: "Catalogs",
    titleTemplate: "%s - Tongji University Press",
    meta: [
      { name: "keywords", content: "Tongji University Press" },
      { name: "description", content: "Tongji University Press" },
    ],
  },

  data() {
    return {
      books: [
        {
          _id: 1,
          cover: require('@/assets/catalogs_2018_fall.png'),
          pdf: "/pdf/TJUP_Catalog_Fall_2018.pdf",
          title: "TJUP Catalog 2018 Fall",
          download_name: "TJUP_Catalog_2018_fall",
        },
        {
          _id: 2,
          cover: require('@/assets/catalogs2018.png'),
          pdf: "/pdf/TJUP_Catalog_2018.pdf",
          title: "TJUP Catalog 2018",
          download_name: "TJUP_Catalog_2018",
        },
        {
          _id: 3,
          cover: require('@/assets/catalogs2019.png'),
          pdf: "/pdf/TJUP_Catalog_2019.pdf",
          title: "TJUP Catalog 2019",
          download_name: "TJUP_Catalog_2019",
        },
        {
          _id: 4,
          cover: require('@/assets/catalogs2021.jpg'),
          pdf: "/pdf/TJUP_Catalog_2018.pdf",
          title: "TJUP Catalog 2021",
          download_name: "TJUP_Catalog_2021",
        },
      ],
    };
  },
  components: {
    appHeader,
    appFooter, 
    bookDivider,
    catalogItem,
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>
$border-color = #000;

.app-index {
  min-width: 1280px;

  .index-wrapper {
    width: 1035px;
    margin: 40px auto 0;

    .catalog-list {
      padding: 0;

      li {
        float: left;
        list-style: none;
        margin: 0 15px 60px 15px;
      }
    }

    .clearfix {
      clear: both;
      overflow: hidden;
    }
  }
}
</style>
