import config from '../config';
import axios from 'axios'

export default{
  getLatestNews (page, limit) {   
    let apiUrl = `${config.API_HOST}/news/list?p=${page}&limit=${limit}`;
    console.log(apiUrl); 
    let promise = axios.get(apiUrl);
    return promise;
  }, 
  getNewsDetail (articleId) { 
    let apiUrl = `${config.API_HOST}/news/detail?id=${articleId}`;
    console.log(apiUrl); 
    let promise = axios.get(apiUrl);
    return promise;
  }
};