<template>
    <div class="app-index"> 
       
        <div class="index-wrapper">
          <div>ffff</div>
        </div>
       
         
    </div>
</template>

<script>
 
 


export default {
    name: 'index',
    metaInfo: {
        title: 'Home',
        titleTemplate: '%s - Tongji University Press',
        meta: [
            {name: 'keywords', content: 'Tongji University Press'},
            {name: 'description', content: 'Tongji University Press'}
        ]
    },
    
    data () {
        return {
            
        }
    },
    components: {
       
    },
    mounted: function(){
        
    },
    methods: {
         
    }
};
</script>

<style lang="stylus" scoped>
$border-color = #000

.app-index
    .index-wrapper
        width 1035px
        margin 40px auto 0

        .index-divider
            margin 50px auto
            border 1px $border-color solid

        .book-list
            padding 0
            li
                float left
                list-style none
                margin 0 9.375px 60px 9.375px

        .clearfix
            clear both
            overflow hidden

</style>
