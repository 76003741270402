<template>
  <div class="app-index">
    <!-- header -->
    <app-header></app-header>
    <div class="index-wrapper">
      <!-- navigator -->
      <!-- <app-navigator 
            :firstNav="'Home'" :firstUrl="'/'" 
            :secondNav="'News'" :secondUrl="'/news/all'"
            :thirdNav="news.title" :thirdUrl="'/news/detail/' + news.id"
            ></app-navigator> -->

      <!-- book divider -->
      <book-divider :content="news.title"></book-divider>

      <!-- news detail -->
      <div class="news-detail">
        <!-- <img :src="news.cover" alt="news-cover">  -->
        <div class="news-content">
          <p v-html="news.content"></p>
        </div>
      </div>

      <!-- detail divider -->
      <div class="detail-divider"></div>
    </div>
    <!-- footer -->
    <app-footer></app-footer>
  </div>
</template>

<script>
import appHeader from "@/components/AppHeader";
import appFooter from "@/components/AppFooter";

import bookDivider from "@/components/book/BookDivider";
import newsApi from "../../../api/news";

export default {
  name: "index",
  metaInfo: {
    title: "Home",
    titleTemplate: "%s - Tongji University Press",
    meta: [
      { name: "keywords", content: "Tongji University Press" },
      { name: "description", content: "Tongji University Press" },
    ],
  },

  data() {
    return {
      news: {
        id: "1",
        title: " TJUP @2017 Frankfurt Book Fair",
        cover: "../../../static/img/news_cover1.png",
        content: "fff",
      },
    };
  },
  components: {
    appHeader,
    appFooter,

    bookDivider,
  },
  mounted: function () {
    this.getNewsDetail();
  },
  methods: {
    getNewsDetail: function () {
      let newsId = this.$route.params.id || "0";
      if (newsId !== "0") {
        let promise = newsApi.getNewsDetail(newsId);
        let self = this;
        promise.then(
          function (response) {
            let news = response.data.data;
            self.news = news;
          },
          function (error) {
            console.log(error);
            alert("获取新闻详情失败" + error.response.data.error);
          }
        );
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
$border-color = #000;

.app-index {
  min-width: 1280px;

  .index-wrapper {
    width: 1035px;
    margin: 40px auto 0;

    .news-detail {
      margin: 50px 0;

      img {
        display: block;
        width: 702px;
        margin: 20px auto;
      }

      .news-content {
        text-align: left;
        font-size: 22px;
        margin-top: 50px;
      }
    }

    .detail-divider {
      margin: 50px auto;
      border: 1px $border-color solid;
    }
  }
}
</style>
