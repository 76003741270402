<template>
    <div class="app-index">
        <!-- mask -->
        <app-mask v-show="keywords == ''"></app-mask>
        <!-- header -->
        <app-header></app-header>
        <div class="index-wrapper">
            <!-- banner -->
            <index-banner></index-banner>
            <!-- divider -->
            <div class="index-divider"></div>
            <!-- booklist -->
            <ul class="book-list">
                <li v-for="book in books" :key="book.id">
                    <book-item :book="book"></book-item>
                </li>
            </ul>

            <div class="clearfix"></div>
        </div>
        <!-- footer -->
        <app-footer></app-footer>

        <!-- searchbar -->
        <search-bar v-show="keywords == ''"></search-bar>
    </div>
</template>

<script>
import appHeader from '@/components/AppHeader';
import appFooter from '@/components/AppFooter';
import indexBanner from '@/components/IndexBanner';
import searchBar from '@/components/SearchBar';
import appMask from '@/components/AppMask';
import bookItem from '@/components/book/BookItem';
import bookApi from '../api/book';

export default {
    name: 'search',
    metaInfo: {
        title: 'Search',
        titleTemplate: '%s - Tongji University Press'
    },
    data () {
        return {
            books: [
                {'id': 1, 'src': '', 'title': 'title1132323'},
                {'id': 2, 'src': '', 'title': 'title232323233'},
                {'id': 3, 'src': '../static/img/logo/logo.png', 'title': 'title44343434343434'},
                {'id': 4, 'src': '', 'title': 'title44343434343434'},
                {'id': 5, 'src': '../static/img/logo/logo.png', 'title': 'title44343434343434'},
                {'id': 6, 'src': '../static/img/logo/logo.png', 'title': 'title44343434343434'}
            ],
            pages: 1, 
        }
    },
    mounted: function(){
        this.getData();
    },
    computed: {
        keywords(){
            let kw = this.$route.query.keywords || '';
            return decodeURI(kw);
        },
        currentPage() {
            let p =  this.$route.query.page || 1;
            return parseInt(p); 
        },
        limit(){
            let l =  this.$route.query.limit || 24;
            return parseInt(l);
        },
        nextPage(){
           return this.currentPage + 1;
        },
        prevPage(){
            let p = this.currentPage - 1;
            if(p <= 0 ){
                p = 1;
            }
            return p;
        }
    },
    watch: {
       '$route': 'getData'
    }, 
    components: {
        appHeader,
        appFooter,
        indexBanner,
        searchBar,
        appMask,
        bookItem
    },
    methods: { 
        getData:function(){
            let promise = bookApi.searchBooks(this.keywords, this.currentPage, this.limit);
            let self = this;
            promise.then(function(response){ 
                if(response.data.data){
                    let books = response.data.data;
                    let total = response.data.total || 1;
                    let pages = Math.ceil(total / self.limit);
                    self.pages = pages;
                    self.books = books
                } 

            }, function(error){
              console.log(error); 
              alert('获取列表失败' + error)
            });
        }
    }
};
</script>

<style lang="stylus" scoped>
$border-color = #000

.app-index
    min-width 1280px

    .index-wrapper
        width 1035px
        margin 40px auto 0

        .index-divider
            margin 50px auto
            border 1px $border-color solid

        .book-list
            padding 0
            li
                float left
                list-style none
                margin 0 9.375px 60px 9.375px

        .clearfix
            clear both
            overflow hidden
</style>
