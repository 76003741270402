<template>
  <div class="app-index">
    <!-- header -->
    <app-header></app-header>
    <div class="index-wrapper">
      <!-- navigator -->
      <!--  <app-navigator :firstNav="'Home'" :firstUrl="'/'" :secondNav="'All'" :secondUrl="'/book/all'"></app-navigator>
         -->
      <!-- book divider -->
      <book-divider :content="'All'"></book-divider>

      <div v-show="item.count > 0" v-for="(item, index) in items" :key="index">
        <!-- list title -->
        <list-title
          :more="item.total > 8"
          :title="item.categoryName"
          :titleUrl="'/book/category?catId=' + item.categoryId"
        ></list-title>
        <!-- book list -->
        <ul class="book-list">
          <li v-for="book in item.books" :key="book.id">
            <book-item :book="book"></book-item>
          </li>
        </ul>
        <div class="clearfix"></div>
      </div>
    </div>
    <!-- footer -->
    <app-footer></app-footer>
  </div>
</template>

<script>
import appHeader from "@/components/AppHeader";
import appFooter from "@/components/AppFooter";
 
import bookDivider from "@/components/book/BookDivider";
import listTitle from "@/components/book/ListTitle";
import bookItem from "@/components/book/BookItem";
import bookApi from "../../api/book";

export default {
  name: "index",
  metaInfo: {
    title: "BookList",
    titleTemplate: "%s - Tongji University Press",
    meta: [
      { name: "keywords", content: "Tongji University Press" },
      { name: "description", content: "Tongji University Press" },
    ],
  },
  data() {
    return {
      books: [
        { id: 1, src: "", title: "title1132323" },
        { id: 2, src: "", title: "title232323233" },
        {
          id: 3,
          src: require("@/assets/logo/logo.png"),
          title: "title44343434343434",
        },
        { id: 4, src: "", title: "title44343434343434" },
        {
          id: 5,
          src: require("@/assets/logo/logo.png"),
          title: "title44343434343434",
        },
        {
          id: 6,
          src: require("@/assets/logo/logo.png"),
          title: "title44343434343434",
        },
      ],
      items: [],
    };
  },
  mounted: function () {
    this.getData();
  },
  components: {
    appHeader,
    appFooter,
 
    bookDivider,
    listTitle,
    bookItem,
  },
  computed: {
    currentPage() {
      let p = this.$route.query.page || 1;
      return parseInt(p);
    },
    limit() {
      let l = this.$route.query.limit || 24;
      return parseInt(l);
    },
    nextPage() {
      return this.currentPage + 1;
    },
    prevPage() {
      let p = this.currentPage - 1;
      if (p <= 0) {
        p = 1;
      }
      return p;
    },
  },
  watch: {
    $route: "getData",
  },
  methods: {
    getData: function () {
      let promise = bookApi.bookIndex();
      let self = this;
      promise.then(
        function (response) {
          let items = response.data;
          self.items = items;
        },
        function (error) {
          console.log(error);
          alert("获取列表失败" + error.response.data.error);
        }
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
$border-color = #000;

.app-index {
  min-width: 1280px;

  .index-wrapper {
    width: 1035px;
    margin: 40px auto 0;

    .book-list {
      padding: 0;

      li {
        float: left;
        list-style: none;
        margin: 0 9.375px 60px 9.375px;
      }
    }

    .clearfix {
      clear: both;
      overflow: hidden;
    }
  }
}
</style>
