<template>
    <div class="book-divider">
        <div class="divider-content" v-text="content"></div>
        <div class="divider-line"></div>
    </div>
</template>

<script>
export default {
    props: {
        content: String
    }
}
</script>

<style lang="stylus" scoped>

$line-color = #A99593

.book-divider
    .divider-content
        font-family SourceSansPro-Bold
        font-size 45px 
        text-align left 

    .divider-line
        border-bottom 2px $line-color solid


</style>

