<template>
  <div class="app-index">
    <!-- header -->
    <app-header></app-header>
    <div class="index-wrapper">
      <!-- navigator -->
      <!-- <app-navigator :firstNav="'Home'" :firstUrl="'/'" :secondNav="'TJUP'" :secondUrl="'/about/tjup'"></app-navigator> -->

      <!-- book divider -->
      <book-divider :content="'TJUP'"></book-divider>

      <!-- tjup -->
      <div class="about-tjup">
        <div class="tjup-left">
          <p><img src="../../static/img/publisherBuilding.png" alt="tjup" /></p>
          <p>
            Established in 1984, led by Ministry of Education of the People’s
            Republic of China, Tongji University Press (TJUP) is the official
            publishing house of Tongji University, one of China’s leading
            universities and research institutions. By establishing Urbanism +
            Architecture (in 2012, TJUP established a high-end professional
            publishing brand - LUMINOCITY, specialized in urbanism &
            architecture), Art + Design and Civil Engineering as its brands,
            Polytechnic and Medicine, Fundamental Disciplines, Humanities,
            German and European Culture publishing as its major publishing
            areas.
          </p>

          <p>
            Under the new situation, TJUP is entering an exciting new phase
            focused on building the international multi-platform.
          </p>
        </div>

        <div class="clearfix"></div>
      </div>

      <!-- divider -->
      <div class="index-divider"></div>
    </div>
    <!-- footer -->
    <app-footer></app-footer>
  </div>
</template>

<script>
import appHeader from "@/components/AppHeader";
import appFooter from "@/components/AppFooter";
import bookDivider from "@/components/book/BookDivider";

export default {
  name: "index",
  metaInfo: {
    title: "TJUP",
    titleTemplate: "%s - Tongji University Press",
    meta: [
      { name: "keywords", content: "Tongji University Press" },
      { name: "description", content: "Tongji University Press" },
    ],
  },
  data() {
    return {};
  },
  components: {
    appHeader,
    appFooter,
    bookDivider,
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>
$border-color = #000;

.app-index {
  min-width: 1280px;

  .index-wrapper {
    width: 1035px;
    margin: 40px auto 0;

    .about-tjup {
      margin: 50px 0;

      .tjup-left {
        text-align: left;
        font-family: SourceSansPro-Regular;
        font-size: 25px;
        float: left;
        width: 100%;

        img {
          width: 100%;
        }
      }

      .tjup-right {
        float: left;
        margin-left: 35px;
        width: 370px;

        img {
          display: block;
          width: 370px;
        }
      }

      .clearfix {
        clear: both;
        overflow: hidden;
      }
    }

    .index-divider {
      margin: 50px auto;
      border: 1px $border-color solid;
    }
  }
}
</style>
