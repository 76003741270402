<template>
    <div class="app-index">

         <!-- mask -->
        <app-mask></app-mask>
        <!-- header -->
        <app-header></app-header>
        <div class="index-wrapper">
            <!-- banner -->
            <index-banner></index-banner>
            <!-- divider -->
            <div class="index-divider"></div>
            <!-- booklist -->
            <ul class="book-list">
                <li v-for="book in books" :key="book.id">
                    <book-item :book="book"></book-item>
                </li>
            </ul>

            <div class="clearfix"></div>
        </div>
        <!-- footer -->
        <app-footer></app-footer>

        <!-- about menu -->
        <about-menu></about-menu>
    </div>
</template>

<script>
import appHeader from '@/components/AppHeader';
import appFooter from '@/components/AppFooter';
import indexBanner from '@/components/IndexBanner';
import bookItem from '@/components/book/BookItem';
import appMask from '@/components/AppMask';
import aboutMenu from '@/components/about/AboutMenu';
import bookApi from '../../api/book';

export default {
    name: 'index',
    metaInfo: {
        title: 'AboutMenu',
        titleTemplate: '%s - Tongji University Press',
        meta: [
            {name: 'keywords', content: 'Tongji University Press'},
            {name: 'description', content: 'Tongji University Press'}
        ]
    },
    mounted: function(){ 
        this.getData();
    },
    data () {
        return {
            books: [
                {'id': 1, 'src': '', 'title': 'title1132323'},
                {'id': 2, 'src': '', 'title': 'title232323233'},
                {'id': 3, 'src': '../../static/img/logo/logo.png', 'title': 'title44343434343434'},
                {'id': 4, 'src': '', 'title': 'title44343434343434'},
                
            ]
        }
    },
    components: {
        appHeader,
        appFooter,
        indexBanner,
        appMask,
        bookItem,
        aboutMenu
    },
    methods: {
        getData:function(){
            let promise = bookApi.getLatestBooks();
            let self = this;
            promise.then(function(response){ 
                let items = response.data;
                self.books = items.data;

            }, function(error){
              console.log(error); 
              alert('获取列表失败' + error.response.data.error)
            });
        }
    }
};
</script>

<style lang="stylus" scoped>
$border-color = #000

.app-index
    min-width 1280px

    .index-wrapper
        width 1035px
        margin 40px auto 0

        .index-divider
            margin 50px auto
            border 1px $border-color solid

        .book-list
            padding 0
            li
                float left
                list-style none
                margin 0 9.375px 60px 9.375px

        .clearfix
            clear both
            overflow hidden
</style>
